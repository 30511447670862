<template>
    <div class="collection-detail-container">
        <div class="top">
            <Header :title="'合集详情'" :bgColor="'#00000000'" :leftArrowType="1" :titleColor="'#ffffff'" :leftClick="goBack">
            </Header>
            <div style="
            display: flex;
            width: 100%;
            padding: 0px 15px;
            box-sizing: border-box;
          ">
                <div>
                    <div v-if="checkCollectionCover() == 1">
                        <img :src="collectionInfo.album_contents[0].cover_pic_url" class="fistImg" />
                    </div>
                    <div v-else class="collectionImg">
                        <img v-for="i in 4" :key="i" :src="collectionInfo.album_contents[i - 1].cover_pic_url" />
                    </div>
                </div>
                <div style="
              width: 100%;
              flex: 1;
              padding-left: 15px;
              box-sizing: border-box;
            ">
                    <p style="color: white; font-size: 18px">
                        {{ collectionInfo.collection_name }}
                    </p>
                    <p style="
                color: white;
                font-size: 14px;
                line-height: 18px;
                margin-top: 10px;
              ">
                        {{ collectionInfo.collection_intro }}
                    </p>

                    <div style="margin-top: 10px; display: flex; flex-wrap: wrap">
                        <p class="tag-style">
                            共{{ collectionInfo.album_contents.length }}个专辑
                        </p>
                        <p class="tag-style">共{{ totalAudioCounts }}个音频</p>
                        <p class="tag-style">预估{{ totalKeshi }}个课时</p>
                        <p class="tag-style">预计学习{{ totalWeeks }}周</p>
                    </div>
                </div>
            </div>
            <div style="height: 1px; background-color: white; margin: 10px 15px"></div>
            <div style="
            color: white;
            font-size: 16px;
            width: 100%;
            padding: 0px 15px;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
          ">
                分类：
                <div v-for="(tag, index) in collectionInfo.collection_labels" :key="index">
                    <p v-if="tag && tag != ''" class="tag1-style"> {{ tag }}</p>
                </div>
            </div>
        </div>
        <div class="collection-content">
            <div style="flex: 1;overflow-y: auto;">
                <p style="color: #333333;font-size: 18px;">专辑列表</p>
                <div style="margin-top: 15px">
                    <div v-for="(content, index) in collectionInfo.album_contents" :key="index" class="album-item"
                        @click="gotoAlbumDetail(content)">
                        <img :src="content.cover_pic_url" style="
                  width: 64px;
                  height: 64px;
                  object-fit: cover;
                  border-radius: 12px;
                " />
                        <div style="
                  width: 100%;
                  padding-left: 15px;
                  flex: 1;
                  box-sizing: border-box;
                ">
                            <p style="color: #333333; font-size: 16px">
                                {{ content.album_name }}
                            </p>
                            <p style="color: #666666; font-size: 14px; margin-top: 8px">
                                {{ content.album_name_sub }}
                            </p>
                            <div style="margin-top: 8px; display: flex; align-items: center">
                                <el-progress :percentage="formatProgress(content)" :show-text="false"
                                    style="flex: 1; padding-right: 10px; box-sizing: border-box"></el-progress>
                                <img v-if="havePurchasePackage == false" src="../../assets/app/ic_locker.png"
                                    style="width: 28px; height: 28px; object-fit: cover" />
                                <p v-else style="color: #6f76c6; font-size: 12px">
                                    {{
                                        content.album_songs.length -
                                        content.album_no_answer_count +
                                        "/" +
                                        content.album_songs.length
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div style="width: 100%;padding-top: 12px;box-sizing: border-box;">
                <p v-if="havePurchasePackage" class="btn-purchased">已购买</p>
                <div v-else style="position: relative;display: flex;">
                    <div style="flex: 1;position: relative;">
                        <p style="text-align: center;color: #c5c5c5;font-size: 12px;margin-bottom: 5px;">原价{{
                            collectionInfo.collection_price_show }}可能点</p>
                        <div style="display: flex;align-items: center;">
                            <p class="btn-purchase" @click="showPurchasePackageDialog = true">{{
                                collectionInfo.actual_collection_price
                            }}可能点解锁合集</p>

                        </div>
                        <div v-if="showReductionPrice"
                            style="position: absolute;top: 5px;right: 0px;display: flex;flex-direction: column;">
                            <img src="../../assets/app/price_hint.png" style="width: 48px;height: auto;;object-fit: cover;">
                            <p
                                style="position: absolute;color: white;font-size: 10px;left: 50%;top: 5px;transform: translateX(-50%);">
                                {{
                                    reductionPriceVaule }}</p>
                        </div>
                    </div>
                    <p v-if="havePurchasePackage == false && collectionInfo.open_audition && collectionInfo.open_audition == 'YES' && collectionInfo.audition_number && collectionInfo.audition_number > 0"
                        class="btn-audition" @click="showAuditionDialog = true">试课</p>
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="showPurchasePackageDialog" title="购买合集" width="95%">
            <p style="line-height: 22px;">{{
                '购买合集需要消耗' + collectionInfo.actual_collection_price + '可能点，还剩下' + (userPoints -
                    collectionInfo.actual_collection_price) + '可能点，您确定购买吗？'
            }}</p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showPurchasePackageDialog = false">取 消</el-button>
                <el-button type="primary" @click="purchasePackage">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog :visible.sync="showPointsNotEnoughDialog" title="购买合集" width="95%">
            <p style="line-height: 22px;">{{ '可能点不足' }}</p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showPointsNotEnoughDialog = false">知道了</el-button>
                <el-button type="primary" @click="gotoPurchasePoints">购买可能点</el-button>
            </span>
        </el-dialog>

        <el-dialog :visible.sync="showPurchaseSuccessDialog" title="提示" width="95%">
            <p style="line-height: 22px;">{{ purchaseSuccessHint }}</p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showPurchaseSuccessDialog = false">待会设置</el-button>
                <el-button type="primary" @click="gotoScheduleTab">现在设置</el-button>
            </span>
        </el-dialog>

        <el-dialog :visible.sync="showAuditionDialog" title="免费试课程" width="95%">
            <p style="line-height: 22px;">{{ '像正常购买一样将音频排入课表，先免费试课，满意再购买。' }}</p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showAuditionDialog = false">取消</el-button>
                <el-button type="primary" @click="doApplyAudition">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Header from "../../components/app/Header.vue";
import { getCollection, getUserByUserID, addPurchase, getMyPurchases, getDefaultKid } from "../../api/app";
import { loginById } from "../../api/keneng";
import {
    setAppUserId,
    setOpenId,
    setAppLoginToken,
    setAppKidId,
    getAppUserId, setHomeFragmentScheduleTabIndex, setHomeTabIndex
} from "../../utils/store";

import { Loading } from "element-ui";

export default {
    name: "index",
    components: {
        Header,
    },
    data() {
        return {
            user_id: getAppUserId(),
            package_id: this.$route.query.id,
            collectionInfo: {},
            totalAudioCounts: 0,
            totalKeshi: 0,
            totalWeeks: 0,
            currentTab: 0,
            userPoints: 0,
            havePurchasePackage: false,
            showReductionPrice: false,
            reductionPriceVaule: 0,
            showPurchasePackageDialog: false,
            showPointsNotEnoughDialog: false,
            showPurchaseSuccessDialog: false,
            showAuditionDialog: false,
            purchaseSuccessHint: '',
            showScheduleTabIndex: 0,
        };
    },
    async mounted() {
        //check是否登录
        if (!this.user_id || this.user_id == undefined || this.user_id == '') {
            //检查是否从公众号点击进来的
            if (this.$route.query.user_id) {
                this.user_id = this.$route.query.user_id
                this.initUserInfo()
            } else {
                this.$router.replace('/app-index')
            }
            return
        }
        this.fetchData();
        this.requestUserpoints()
    },
    methods: {

        async initUserInfo() {
            let loadingInstance = Loading.service({
                fullscreen: true,
                text: "加载中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.8)",
            });
            //调用获取用户信息接口
            let userResult = await loginById(this.user_id)
            let success = true;
            if (userResult.data) {
                if (userResult.data.code == 0) {
                    let user_data = userResult.data.data
                    setAppUserId(this.user_id)
                    setOpenId(user_data.open_id)
                    setAppLoginToken(user_data.login_token)
                    let kidIdResult = await getDefaultKid(this.user_id)
                    let kid_id = kidIdResult.data.data._id.$id
                    setAppKidId(kid_id)
                } else {
                    success = false
                }
            } else {
                success = false
            }
            loadingInstance.close()
            if (success == false) {
                this.$router.replace('/app-index')
                return
            }
            this.fetchData();
            this.requestUserpoints()
        },


        doApplyAudition() {
            let price_info = {
                total_album_price: this.collectionInfo.total_album_price ? this.collectionInfo.total_album_price : '',
                total_purchased_price: this.collectionInfo.total_purchased_price ? this.collectionInfo.total_purchased_price : '',
                total_unpurchase_price: this.collectionInfo.total_unpurchase_price ? this.collectionInfo.total_unpurchase_price : '',
            }
            let params = {
                function: 'addPurchase',
                user_id: getAppUserId(),
                purchase_type: 'collection',
                content_id: this.package_id,
                apply_type: 'audition',
                price: this.collectionInfo.actual_collection_price,
                price_info: price_info
            }
            addPurchase(params).then((res) => {
                let result = res.data
                if (result.code == 0) {
                    this.showAuditionDialog = false
                    this.$message.success('申请试课成功')
                    this.doShowPurchaseDialog()
                } else {
                    this.$message.error(result.msg)
                }
            })
        },
        async doShowPurchaseDialog() {
            let current_time = new Date().valueOf() / 1000
            let reuslt = await getMyPurchases(getAppUserId(), current_time)
            let orderSize = reuslt.data.code == 0 ? reuslt.data.data.length : 0
            if (orderSize <= 1) {
                this.purchaseSuccessHint = '你已经成功购买第一个课程，请前往“智能排课-时间安排”设置小朋友的收听时间。'
                this.showScheduleTabIndex = 1
            } else {
                this.purchaseSuccessHint = '你已经成功购买此课程，（共' + this.totalAudioCounts + '个音频），请前往“智能排课-内容安排”设置每次收听的音频数量吧。'
                this.showScheduleTabIndex = 2
            }
            this.showPurchaseSuccessDialog = true
        },

        gotoScheduleTab() {
            setHomeTabIndex(0)
            setHomeFragmentScheduleTabIndex(this.showScheduleTabIndex)
            this.$router.replace({
                path: '/app-home'
            })
        },
        gotoPurchasePoints() {
            this.$router.push({
                path: '/app-products'
            })
            this.showPointsNotEnoughDialog = false
        },

        purchasePackage() {
            this.showPurchasePackageDialog = false
            let price_info = {
                total_album_price: this.collectionInfo.total_album_price ? this.collectionInfo.total_album_price : '',
                total_purchased_price: this.collectionInfo.total_purchased_price ? this.collectionInfo.total_purchased_price : '',
                total_unpurchase_price: this.collectionInfo.total_unpurchase_price ? this.collectionInfo.total_unpurchase_price : '',
            }
            let params = {
                function: 'addPurchase',
                user_id: getAppUserId(),
                purchase_type: 'collection',
                apply_type: 'purchase',
                content_id: this.package_id,
                price: this.collectionInfo.actual_collection_price,
                price_info: price_info
            }
            addPurchase(params).then((res) => {
                let result = res.data
                if (result.code == 0) {
                    this.$message.success('恭喜你解锁' + this.collectionInfo.collection_name)
                    this.fetchData()
                    this.requestUserpoints()
                    this.doShowPurchaseDialog()
                } else if (result.code == -1) {
                    this.showPointsNotEnoughDialog = true
                } else {
                    this.$message.error(result.msg)
                }
            })
        },

        gotoAlbumDetail(album) {
            this.$router.push({
                path: '/app-album',
                query: {
                    id: album._id.$id
                }
            })
        },

        requestUserpoints() {
            getUserByUserID(getAppUserId()).then((res) => {
                if (res.data.code == 0) {
                    this.userPoints = res.data.data.keneng_points;
                }

            });
        },
        formartReductionPrice() {
            let collection_price_show = 0
            if (this.collectionInfo.collection_price_show) {
                collection_price_show = this.collectionInfo.collection_price_show
            }
            if (collection_price_show > 0) {
                this.showReductionPrice = true
                this.reductionPriceVaule = '-' + parseInt(((collection_price_show - this.collectionInfo.actual_collection_price) / collection_price_show) * 100) + '%'
            }
        },

        formatProgress(content) {
            let album_no_answer_count = content.album_no_answer_count;
            let album_songs = content.album_songs.length;
            let answer_count = album_songs - album_no_answer_count;
            let progress = parseInt((answer_count / album_songs) * 100);
            return progress;
        },

        fetchData() {

            let loadingInstance = Loading.service({
                fullscreen: true,
                text: "加载中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.8)",
            });
            getCollection(this.package_id).then((res) => {
                this.collectionInfo = res.data.data;
                this.collectionInfo.album_contents.forEach((album) => {
                    this.totalAudioCounts =
                        this.totalAudioCounts + album.album_songs.length;
                });
                this.totalKeshi = parseInt(Math.ceil(this.totalAudioCounts / 5));
                this.totalWeeks = parseInt(Math.ceil(this.totalAudioCounts / (5 * 2)));

                let collection_purchase_available =
                    this.collectionInfo.collection_purchase_available;

                if (collection_purchase_available == "YES") {
                    let collection_price = this.collectionInfo.actual_collection_price;
                    let purchase_status = this.collectionInfo.purchase_status;
                    if (collection_price > 0 && purchase_status == false) {
                        this.havePurchasePackage = false;
                    } else {
                        this.havePurchasePackage = true;
                    }
                } else {
                    this.havePurchasePackage = true;
                }
                this.formartReductionPrice()
                loadingInstance.close()
            });
        },

        checkCollectionCover() {
            let albumContents = this.collectionInfo.album_contents;
            if (albumContents && albumContents.length < 4) {
                return 1;
            }
            let enbale = 0;
            for (let i = 0; i < 4; i++) {
                let album = albumContents[i];
                if (!album.cover_pic_url) {
                    enbale = 1;
                    break;
                }
                return enbale;
            }
        },

        goBack() {
            this.$router.replace('/app-home')
        },
    },
};
</script>
  
<style lang="less" scoped>
.btn-audition {
    cursor: pointer;
    height: 42px;
    line-height: 42px;
    text-align: center;
    color: white;
    border-radius: 18px;
    font-size: 16px;
    padding: 0px 24px;
    margin-top: 18px;
    margin-left: 10px;
    background-color: #5753b0;
}

.btn-purchased {
    width: 100%;
    height: 42px;
    line-height: 42px;
    text-align: center;
    color: #686CC0;
    background-color: #D1CFF6;
    border-radius: 24px;
    font-size: 16px;
}

.btn-purchase {
    cursor: pointer;
    width: 100%;
    height: 42px;
    line-height: 42px;
    text-align: center;
    color: white;
    background-color: #ff5e45;
    border-radius: 24px;
    font-size: 16px;
}

.album-item {
    cursor: pointer;
    display: flex;
    margin-bottom: 25px;
}

.tag1-style {
    color: white;
    font-size: 12px;
    padding: 4px 8px;
    box-sizing: border-box;
    margin-right: 10px;
    margin-bottom: 8px;
    background-color: rgba(0, 0, 0, 0.38);
    border-radius: 14px;
}

.tag-style {
    color: white;
    font-size: 12px;
    padding: 4px 8px;
    box-sizing: border-box;
    margin-right: 10px;
    margin-bottom: 8px;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
}

.collection-detail-container {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background-color: white;

    .top {
        position: relative;
        width: 100%;
        height: 400px;
        background: linear-gradient(135deg, #727bc9 0%, #5753b0 100%);
    }

    .collection-content {
        position: absolute;
        margin-top: 280px;
        width: 100%;
        height: calc(100vh - 280px);
        background: white;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        padding: 15px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
}

.fistImg {
    width: 110px;
    height: 110px;
    border-style: solid;
    border-width: 1px;
    border-color: white;
    border-radius: 12px;
}

.collectionImg {
    display: flex;
    flex-wrap: wrap;
    width: 110px;
    height: 110px;
    border-style: solid;
    border-width: 1px;
    border-color: white;
    border-radius: 12px;
    box-sizing: border-box;
    flex-shrink: 0;

    img {
        width: 50%;
        height: 50%;
    }

    img:nth-child(1) {
        border-radius: 12px 0 0 0;
    }

    img:nth-child(2) {
        border-radius: 0 12px 0 0;
    }

    img:nth-child(3) {
        border-radius: 0 0 0 12px;
    }

    img:nth-child(4) {
        border-radius: 0 0 12px 0;
    }
}
</style>
  